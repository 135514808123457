@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-burger: "\EA01"
$icon-arrow_down: "\EA02"
$icon-play_button: "\EA03"
$icon-search: "\EA04"
$icon-shop: "\EA05"
$icon-article: "\EA06"
$icon-calendar: "\EA07"
$icon-slider-next: "\EA08"
$icon-slider-prev: "\EA09"
$icon-colon: "\EA10"
$icon-date: "\EA11"
$icon-facebook2: "\EA12"
$icon-heart: "\EA13"
$icon-instagram2: "\EA14"
$icon-letter: "\EA15"
$icon-message: "\EA16"
$icon-share: "\EA17"
$icon-tag: "\EA18"
$icon-twitter_share: "\EA19"
$icon-facebook_share: "\EA20"
$icon-telegram_share: "\EA21"
$icon-share_link: "\EA22"
$icon-arrow_right: "\EA23"
$icon-video_tag: "\EA24"
$icon-close: "\EA25"
$icon-fanshop_tag: "\EA26"
$icon-photo: "\EA27"
$icon-phone: "\EA28"
$icon-circle-up: "\EA29"
$icon-football-ball: "\EA30"
$icon-arrow-up: "\EA31"
$icon-arrow_down2: "\EA32"
$icon-ball: "\EA33"
$icon-clock: "\EA34"
$icon-field: "\EA35"
$icon-penalties: "\EA36"
$icon-red_card: "\EA37"
$icon-yellow_card: "\EA38"
$icon-cleats: "\EA39"
$icon-football-player: "\EA40"
$icon-gloves: "\EA41"
$icon-whistle: "\EA42"
$icon-search_big: "\EA43"
$icon-pencil: "\EA44"
$icon-account: "\EA45"
$icon-fan_zone: "\EA46"
$icon-magnifier: "\EA47"
$icon-marker: "\EA48"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-burger:before
	content: $icon-burger
.icon-arrow_down:before
	content: $icon-arrow_down
.icon-play_button:before
	content: $icon-play_button
.icon-search:before
	content: $icon-search
.icon-shop:before
	content: $icon-shop
.icon-article:before
	content: $icon-article
.icon-calendar:before
	content: $icon-calendar
.icon-slider-next:before
	content: $icon-slider-next
.icon-slider-prev:before
	content: $icon-slider-prev
.icon-colon:before
	content: $icon-colon
.icon-date:before
	content: $icon-date
.icon-facebook2:before
	content: $icon-facebook2
.icon-heart:before
	content: $icon-heart
.icon-instagram2:before
	content: $icon-instagram2
.icon-letter:before
	content: $icon-letter
.icon-message:before
	content: $icon-message
.icon-share:before
	content: $icon-share
.icon-tag:before
	content: $icon-tag
.icon-twitter_share:before
	content: $icon-twitter_share
.icon-facebook_share:before
	content: $icon-facebook_share
.icon-telegram_share:before
	content: $icon-telegram_share
.icon-share_link:before
	content: $icon-share_link
.icon-arrow_right:before
	content: $icon-arrow_right
.icon-video_tag:before
	content: $icon-video_tag
.icon-close:before
	content: $icon-close
.icon-fanshop_tag:before
	content: $icon-fanshop_tag
.icon-photo:before
	content: $icon-photo
.icon-phone:before
	content: $icon-phone
.icon-circle-up:before
	content: $icon-circle-up
.icon-football-ball:before
	content: $icon-football-ball
.icon-arrow-up:before
	content: $icon-arrow-up
.icon-arrow_down2:before
	content: $icon-arrow_down2
.icon-ball:before
	content: $icon-ball
.icon-clock:before
	content: $icon-clock
.icon-field:before
	content: $icon-field
.icon-penalties:before
	content: $icon-penalties
.icon-red_card:before
	content: $icon-red_card
.icon-yellow_card:before
	content: $icon-yellow_card
.icon-cleats:before
	content: $icon-cleats
.icon-football-player:before
	content: $icon-football-player
.icon-gloves:before
	content: $icon-gloves
.icon-whistle:before
	content: $icon-whistle
.icon-search_big:before
	content: $icon-search_big
.icon-pencil:before
	content: $icon-pencil
.icon-account:before
	content: $icon-account
.icon-fan_zone:before
	content: $icon-fan_zone
.icon-magnifier:before
	content: $icon-magnifier
.icon-marker:before
	content: $icon-marker
